var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"element-loading-text":"数据较多，拼命加载中..."}},[_vm._m(0),_c('div',{staticClass:"row3"},[_c('div',{staticClass:"col publicBox"},[_c('div',{staticClass:"tit"},[_vm._v(" "+_vm._s(_vm.currentYear)+"年公司订单目标进度 "),_c('i',{staticClass:"iconfont icon-shezhi",on:{"click":function () {
              _vm.yearDialogVisible = true;
            }}})]),_c('div',{staticClass:"con"},[_c('el-progress',{attrs:{"type":"circle","percentage":_vm.area.orderTotal&&_vm.area.orderTarget?
            (Number(((_vm.area.orderTotal / _vm.area.orderTarget) * 100).toFixed(2)) >=
            100
              ? 100
              : Number(((_vm.area.orderTotal / _vm.area.orderTarget) * 100).toFixed(2))):0,"stroke-width":15,"format":_vm.format}}),_c('div',{staticClass:"year",staticStyle:{"display":"flex","flex-direction":"column","justify-content":"center"}},[_c('span',{staticClass:"total"},[_vm._v("年度目标总额")]),_c('span',{staticClass:"money"},[_vm._v("¥ "+_vm._s(_vm.area.orderTarget))]),_c('span',{staticClass:"total"},[_vm._v("累计订单总额")]),_c('el-button',{staticClass:"text",attrs:{"type":"text"},on:{"click":function () {
                _vm.collect(
                  'orderTotal',
                  '累计订单总额',
                  [
                    {
                      name: '订单编号',
                      type: 'input',
                      modelKey: 'orderIdAndName'
                    },
                    {
                      name: '所属客户',
                      type: 'input',
                      modelKey: 'customerName'
                    }
                  ],
                  [
                    { name: '订单编号', colProp: ['orderNo'], url: '/customer/total-order', paramsArr: [{urlKey: 'orderNo', paramsKey: 'orderNo'}, {urlKey: 'orderId', paramsKey: 'orderId'}] },
                    { name: '订单名称', colProp: ['orderName'] },
                    { name: '所属客户', colProp: ['customerName'] },
                    {
                      name: '服务时间',
                      colProp: ['startTime', 'endTime']
                    },
                    { name: '所属公司', colProp: ['companyName'] }
                  ]
                );
              }}},[_vm._v("￥"+_vm._s(_vm.area.orderTotal || "0"))])],1)],1)]),_c('div',{staticClass:"col publicBox"},[_c('div',{staticClass:"tit2"},[_vm._v(" 店铺数服务状态统计 ")]),_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[(_vm.flag)?_c('pie-charts',{attrs:{"centerTxt":_vm.area.totalShopNum || '0',"subTxt":'累计店铺总数',"pieName":'main',"pieColor":[
            '#ff9e09',
            '#52c41a',
            '#1890ff'
          ],"pieData":[
            { value: _vm.area.endServiceShopNum, name: '累计终止服务店铺数' },
            { value: _vm.area.unServiceShopNum, name: '待服务店铺数' },
            { value: _vm.area.serviceShopNum, name: '服务中的店铺数' }
          ],"comId":_vm.comId}}):_vm._e(),_c('div',{staticClass:"year",staticStyle:{"display":"flex","flex-direction":"column","justify-content":"center"}},[_c('span',{staticClass:"total"},[_vm._v("服务中的店铺数")]),_c('el-button',{staticClass:"text shop1",attrs:{"type":"text"},on:{"click":function () {
                _vm.collect(
                  'serviceShopNum',
                  '今日服务中店铺数',
                  [
                    {
                      name: '所属客户',
                      type: 'input',
                      modelKey: 'customerName'
                    }
                  ],
                  [
                    { name: '店铺名称', colProp: ['shopName'], url: '/customer/company-shop-list', paramsArr: [{urlKey: 'shopName', paramsKey: 'shopName'}, {urlKey: 'shopId', paramsKey: 'shopId'}] },
                    { name: '所属客户', colProp: ['customerName'] },
                    { name: '服务时间', colProp: ['startDate', 'endDate'] }
                  ]
                );
              }}},[_vm._v(_vm._s(_vm.area.serviceShopNum || 0))]),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"未到服务时间的店铺总数","placement":"bottom","enterable":""}},[_c('span',{staticClass:"total topPx"},[_vm._v("待服务店铺数 "),_c('img',{staticClass:"tit-img",attrs:{"src":require("../../../assets/images/question.png")}})])]),_c('el-button',{staticClass:"text shop2",attrs:{"type":"text"},on:{"click":function () {
                _vm.collect(
                  'unServiceShopNum',
                  '待服务店铺数',
                  [
                    {
                      name: '所属客户',
                      type: 'input',
                      modelKey: 'customerName'
                    }
                  ],
                  [
                    { name: '店铺名称', colProp: ['shopName'], url: '/customer/company-shop-list', paramsArr: [{urlKey: 'shopName', paramsKey: 'shopName'}, {urlKey: 'shopId', paramsKey: 'shopId'}] },
                    { name: '所属客户', colProp: ['customerName'] },
                    { name: '服务时间', colProp: ['startDate', 'endDate'] }
                  ]
                );
              }}},[_vm._v(_vm._s(_vm.area.unServiceShopNum || 0))]),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"当前所有服务终止的店铺总数","placement":"bottom","enterable":""}},[_c('span',{staticClass:"total topPx"},[_vm._v("累计终止服务店铺数"),_c('img',{staticClass:"tit-img",attrs:{"src":require("../../../assets/images/question.png")}})])]),_c('el-button',{staticClass:"text shop3",attrs:{"type":"text"},on:{"click":function () {
                _vm.collect(
                  'endServiceShopNum',
                  '累计终止服务店铺数',
                  [
                    {
                      name: '店铺名称',
                      type: 'input',
                      modelKey: 'shopName'
                    },
                    {
                      name: '所属客户',
                      type: 'input',
                      modelKey: 'customerName'
                    }
                  ],
                  [
                    { name: '店铺名称', colProp: ['shopName'], url: '/customer/company-shop-list', paramsArr: [{urlKey: 'shopName', paramsKey: 'shopName'}, {urlKey: 'shopId', paramsKey: 'shopId'}] },
                    { name: '所属客户', colProp: ['customerName'] },
                    { name: '所属平台', colProp: ['platformName'] },
                    { name: '关联订单数', colProp: ['orderNum'] } ]
                );
              }}},[_vm._v(_vm._s(_vm.area.endServiceShopNum || 0))])],1)],1)]),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.markLoading),expression:"markLoading"}],staticClass:"col publicThreeBox",attrs:{"element-loading-text":"数据较多，拼命加载中..."}},[_c('div',{staticClass:"tit"},[_vm._v(" 营销数据概览 "),_c('div',[_c('el-radio-group',{on:{"change":_vm.changeRadio},model:{value:(_vm.label),callback:function ($$v) {_vm.label=$$v},expression:"label"}},[_c('el-radio-button',{attrs:{"label":0}},[_vm._v("昨日")]),_c('el-radio-button',{attrs:{"label":1}},[_vm._v("今日")])],1)],1)]),_c('ul',{staticStyle:{"width":"100%"}},[_c('li',{staticStyle:{"display":"flex"}},[_c('span',{staticClass:"everyone"},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"所有处于正在服务的订单的客户数量","placement":"bottom","enterable":""}},[_c('span',{staticClass:"sale-tit"},[_vm._v("有效客户数"),_c('img',{staticClass:"tit-img",attrs:{"src":require("../../../assets/images/question.png")}})])]),_c('el-button',{staticClass:"sale-datas",attrs:{"type":"text"},on:{"click":function () {
                _vm.collect(
                  'servicingCustomerNum',
                  '有效客户数',
                  [
                    {
                      name: '客户名称',
                      type: 'input',
                      modelKey: 'customerName'
                    }
                  ],
                  [
                    { name: '客户名称', colProp: ['customerName'], url: '/customer/customer-pool', paramsArr: [{urlKey: 'name', paramsKey: 'customerName'}, {urlKey: 'id', paramsKey: 'customerId'}] },
                    { name: '所属公司', colProp: ['companyName'] },
                    { name: '服务时间', colProp: ['startTime','endTime'] } ]
                );
              }}},[_vm._v(_vm._s((_vm.markdataFlag? _vm.markData.servicingCustomerNum : _vm.area.servicingCustomerNum) || 0))])],1),_c('span',{staticClass:"everyone"},[_c('span',{staticClass:"sale-tit"},[_vm._v("当前客户线索总数")]),_c('el-button',{staticClass:"sale-datas",attrs:{"type":"text"},on:{"click":function () {
                _vm.collect(
                  'customerTotalNum',
                  '当前客户线索总数',
                  [
                    {
                      name: '客户名称',
                      type: 'input',
                      modelKey: 'customerName'
                    },
                    { name: '客户状态', type: 'select', modelKey: 'customerState',selOpt: [{id: 0,name: '公海中'},{id: 1,name: '被销售捞取'},{id: 2,name: '已成单'},{id: 3,name: '已分配'}] }
                  ],
                  [
                    { name: '客户名称', colProp: ['customerName'], url: '/customer/customer-sea', paramsArr: [{urlKey: 'name', paramsKey: 'customerName'}, {urlKey: 'id', paramsKey: 'customerId'}] },
                    { name: '客户状态', colProp: ['stateName'] } ]
                );
              }}},[_vm._v(_vm._s((_vm.markdataFlag? _vm.markData.customerTotalNum : _vm.area.customerTotalNum) || 0))])],1),_c('span',{staticClass:"everyone"},[_c('span',{staticClass:"sale-tit"},[_vm._v("新增机会跟进数")]),_c('el-button',{staticClass:"sale-datas",attrs:{"type":"text"},on:{"click":function () {
                _vm.collect(
                  'customerLaoNum',
                  '新增机会跟进数',
                  [
                    {
                      name: '销售机会名称',
                      type: 'input',
                      modelKey: 'chanceName'
                    },
                    {
                      name: '客户名称',
                      type: 'input',
                      modelKey: 'customerName'
                    }
                  ],
                  [
                    { name: '销售机会名称', colProp: ['chanceName'], url: '/customer/sales-leads', paramsArr: [{urlKey: 'name', paramsKey: 'chanceName'}, {urlKey: 'id', paramsKey: 'chanceId'}, {urlKey: 'customerId', paramsKey: 'customerId'}] },
                    { name: '客户名称', colProp: ['customerName'] }
                  ]
                );
              }}},[_vm._v(_vm._s((_vm.markdataFlag? _vm.markData.customerLaoNum : _vm.area.customerLaoNum) || 0))])],1)]),_c('li',{staticStyle:{"display":"flex"}},[_c('span',{staticClass:"everyone"},[_c('span',{staticClass:"sale-tit"},[_vm._v("销售机会总数")]),_c('el-button',{staticClass:"sale-datas",attrs:{"type":"text"},on:{"click":function () {
                _vm.collect(
                  'chanceTotalNum',
                  '销售机会总数',
                  [
                    {
                      name: '销售机会名称',
                      type: 'input',
                      modelKey: 'chanceName'
                    },
                    {
                      name: '客户名称',
                      type: 'input',
                      modelKey: 'customerName'
                    }
                  ],
                  [
                    { name: '销售机会名称', colProp: ['chanceName'], url: '/customer/sales-leads', paramsArr: [{urlKey: 'name', paramsKey: 'chanceName'}, {urlKey: 'id', paramsKey: 'chanceId'}, {urlKey: 'customerId', paramsKey: 'customerId'}] },
                    { name: '客户名称', colProp: ['customerName'] },
                    { name: '销售阶段', colProp: ['stageName'] },
                    { name: '机会状态', colProp: ['stateName'] }
                  ]
                );
              }}},[_vm._v(_vm._s((_vm.markdataFlag? _vm.markData.chanceTotalNum : _vm.area.chanceTotalNum) || 0))])],1),_c('span',{staticClass:"everyone"},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"所有处于所有服务时间内的订单数量","placement":"bottom","enterable":""}},[_c('span',{staticClass:"sale-tit"},[_vm._v("有效订单数"),_c('img',{staticClass:"tit-img",attrs:{"src":require("../../../assets/images/question.png")}})])]),_c('el-button',{staticClass:"sale-datas",attrs:{"type":"text"},on:{"click":function () {
                _vm.collect(
                  'validOrderNum',
                  '有效订单数',
                  [
                    {
                      name: '订单编号',
                      type: 'input',
                      modelKey: 'orderIdAndName'
                    },
                    {
                      name: '所属客户',
                      type: 'input',
                      modelKey: 'customerName'
                    }
                  ],
                  [
                    { name: '订单编号', colProp: ['orderNo'], url: '/customer/total-order', paramsArr: [{urlKey: 'orderNo', paramsKey: 'orderNo'}, {urlKey: 'orderId', paramsKey: 'orderId'}] },
                    { name: '订单名称', colProp: ['orderName'] },
                    { name: '所属客户', colProp: ['customerName'] },
                    { name: '服务时间', colProp: ['startTime','endTime'] }
                  ]
                );
              }}},[_vm._v(_vm._s((_vm.markdataFlag? _vm.markData.validOrderNum : _vm.area.validOrderNum) || 0))])],1),_c('span',{staticClass:"everyone"},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"显示7天内服务到期的订单总数","placement":"bottom","enterable":""}},[_c('span',{staticClass:"sale-tit"},[_vm._v("续签预警量"),_c('img',{staticClass:"tit-img",attrs:{"src":require("../../../assets/images/question.png")}})])]),_c('el-button',{staticClass:"sale-datas",attrs:{"type":"text"},on:{"click":function () {
                _vm.collect(
                  'warningOrderNum',
                  '续签预警量',
                  [
                    {
                      name: '订单编号',
                      type: 'input',
                      modelKey: 'orderIdAndName'
                    },
                    {
                      name: '所属客户',
                      type: 'input',
                      modelKey: 'customerName'
                    }
                  ],
                  [
                    { name: '订单编号', colProp: ['orderNo'], url: '/customer/total-order', paramsArr: [{urlKey: 'orderNo', paramsKey: 'orderNo'}, {urlKey: 'orderId', paramsKey: 'orderId'}] },
                    { name: '订单名称', colProp: ['orderName'] },
                    { name: '所属客户', colProp: ['customerName'] },
                    { name: '服务时间', colProp: ['startTime','endTime'] }
                  ]
                );
              }}},[_vm._v(_vm._s((_vm.markdataFlag? _vm.markData.warningOrderNum : _vm.area.warningOrderNum) || 0))])],1)]),_c('li',{staticStyle:{"display":"flex"}},[_c('span',{staticClass:"everyone"},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"续签成功的订单总数","placement":"bottom","enterable":""}},[_c('span',{staticClass:"sale-tit"},[_vm._v("续签订单数"),_c('img',{staticClass:"tit-img",attrs:{"src":require("../../../assets/images/question.png")}})])]),_c('el-button',{staticClass:"sale-datas",attrs:{"type":"text"},on:{"click":function () {
                _vm.collect(
                  'completeOrderNum',
                  '续签订单数',
                  [
                    {
                      name: '订单编号',
                      type: 'input',
                      modelKey: 'orderIdAndName'
                    },
                    {
                      name: '所属客户',
                      type: 'input',
                      modelKey: 'customerName'
                    }
                  ],
                  [
                    { name: '订单编号', colProp: ['orderNo'], url: '/customer/total-order', paramsArr: [{urlKey: 'orderNo', paramsKey: 'orderNo'}, {urlKey: 'orderId', paramsKey: 'orderId'}] },
                    { name: '订单名称', colProp: ['orderName'] },
                    { name: '所属客户', colProp: ['customerName'] },
                    { name: '所属店铺', colProp: ['shopName'] },
                    { name: '服务时间', colProp: ['startTime','endTime'] },
                    { name: '订单状态', colProp: ['status'] }
                  ]
                );
              }}},[_vm._v(_vm._s((_vm.markdataFlag? _vm.markData.completeOrderNum : _vm.area.completeOrderNum) || 0))])],1),_c('span',{staticClass:"everyone"},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"没有续签成功的订单总数","placement":"bottom","enterable":""}},[_c('span',{staticClass:"sale-tit"},[_vm._v("流失订单数"),_c('img',{staticClass:"tit-img",attrs:{"src":require("../../../assets/images/question.png")}})])]),_c('el-button',{staticClass:"sale-datas",attrs:{"type":"text"},on:{"click":function () {
                _vm.collect(
                  'failOrderNum',
                  '流失订单数',
                  [
                    {
                      name: '订单编号',
                      type: 'input',
                      modelKey: 'orderIdAndName'
                    },
                    {
                      name: '所属客户',
                      type: 'input',
                      modelKey: 'customerName'
                    }
                  ],
                  [
                    { name: '订单编号', colProp: ['orderNo'], url: '/customer/total-order', paramsArr: [{urlKey: 'orderNo', paramsKey: 'orderNo'}, {urlKey: 'orderId', paramsKey: 'orderId'}] },
                    { name: '订单名称', colProp: ['orderName'] },
                    { name: '所属客户', colProp: ['customerName'] },
                    { name: '所属店铺', colProp: ['shopName'] },
                    { name: '服务时间', colProp: ['startTime','endTime'] },
                    { name: '订单状态', colProp: ['status'] }
                  ]
                );
              }}},[_vm._v(_vm._s((_vm.markdataFlag? _vm.markData.failOrderNum : _vm.area.failOrderNum) || 0))])],1),_c('span',{staticClass:"everyone"},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"非本月未收金额订单总数","placement":"bottom","enterable":""}},[_c('span',{staticClass:"sale-tit"},[_vm._v("历史欠款订单"),_c('img',{staticClass:"tit-img",attrs:{"src":require("../../../assets/images/question.png")}})])]),_c('el-button',{staticClass:"sale-datas",attrs:{"type":"text"},on:{"click":function () {
                _vm.collect(
                  'historyUnpaidOrderNum',
                  '历史欠款订单',
                  [
                    {
                      name: '订单编号/名称',
                      type: 'input',
                      modelKey: 'shopName'
                    },
                    {
                      name: '客户名称',
                      type: 'input',
                      modelKey: 'customerName'
                    },
                    {
                      name: '销售负责人',
                      type: 'input',
                      modelKey: 'marketName'
                    }
                  ],
                  [
                    { name: '订单编号', colProp: ['orderNo'], url: '/customer/total-order', paramsArr: [{urlKey: 'orderNo', paramsKey: 'orderNo'}, {urlKey: 'orderId', paramsKey: 'orderId'}] },
                    { name: '订单名称', colProp: ['orderName'] },
                    { name: '客户名称', colProp: ['customerName'] },
                    { name: '服务时间', colProp: ['startTime','endTime'] },
                    { name: '历史欠款金额', colProp: ['historyUnCollectionPrice'] },
                    { name: '销售负责人', colProp: ['marketName'] },
                    { name: '备注', colProp: ['note'] }
                  ]
                );
              }}},[_vm._v(_vm._s((_vm.markdataFlag? _vm.markData.historyUnpaidOrderNum : _vm.area.historyUnpaidOrderNum) || 0))])],1)])])])]),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading2),expression:"loading2"}],staticClass:"row4",attrs:{"element-loading-text":"数据较多，拼命加载中..."}},[_c('div',{staticClass:"col publicColW col4-1"},[(_vm.flag)?_c('line-charts',{attrs:{"lineId":[
          'echartsLineA',
          'echartsLineB',
          'echartsLineC',
          'echartsLineD'
        ],"homeData":_vm.line ? _vm.lineData : _vm.area.addOrder,"title":'新增数据统计',"type":_vm.childType},on:{"changeDate":_vm.changeDate}}):_vm._e()],1),_c('div',{staticClass:"col publicColW2 col4-2"},[_c('div',{staticClass:"publicTit"},[_vm._v("各平台服务中的店铺总数")]),_c('ul',{staticClass:"con"},[_c('li',{staticClass:"con-item"},[_vm._m(1),_c('el-button',{staticClass:"pubTextBtn",attrs:{"type":"text"},on:{"click":function () {
                _vm.collect(
                  'taobaoShopNum',
                  '淘宝服务中店铺数',
                  [
                    {
                      name: '所属客户',
                      type: 'input',
                      modelKey: 'customerName'
                    }
                  ],
                  [
                    { name: '店铺名称', colProp: ['shopName'], url: '/customer/company-shop-list', paramsArr: [{urlKey: 'shopName', paramsKey: 'shopName'}, {urlKey: 'shopId', paramsKey: 'shopId'}] },
                    { name: '所属客户', colProp: ['customerName'] },
                    { name: '服务时间', colProp: ['startDate', 'endDate'] }
                  ]
                );
              }}},[_vm._v(_vm._s((_vm.line ? _vm.lineData.taobaoShopNum : _vm.area.addOrder && _vm.area.addOrder.taobaoShopNum) || "0"))])],1),_c('li',{staticClass:"con-item"},[_vm._m(2),_c('el-button',{staticClass:"pubTextBtn",attrs:{"type":"text"},on:{"click":function () {
                _vm.collect(
                  'tianMaoShopNum',
                  '天猫服务中店铺数',
                  [
                    {
                      name: '所属客户',
                      type: 'input',
                      modelKey: 'customerName'
                    }
                  ],
                  [
                    { name: '店铺名称', colProp: ['shopName'], url: '/customer/company-shop-list', paramsArr: [{urlKey: 'shopName', paramsKey: 'shopName'}, {urlKey: 'shopId', paramsKey: 'shopId'}] },
                    { name: '所属客户', colProp: ['customerName'] },
                    { name: '服务时间', colProp: ['startDate', 'endDate'] }
                  ]
                );
              }}},[_vm._v(_vm._s((_vm.line ? _vm.lineData.tianMaoShopNum : _vm.area.addOrder && _vm.area.addOrder.tianMaoShopNum) || "0"))])],1),_c('li',{staticClass:"con-item"},[_vm._m(3),_c('el-button',{staticClass:"pubTextBtn",attrs:{"type":"text"},on:{"click":function () {
                _vm.collect(
                  'JDShopNum',
                  '京东服务中店铺数',
                  [
                    {
                      name: '所属客户',
                      type: 'input',
                      modelKey: 'customerName'
                    }
                  ],
                  [
                    { name: '店铺名称', colProp: ['shopName'], url: '/customer/company-shop-list', paramsArr: [{urlKey: 'shopName', paramsKey: 'shopName'}, {urlKey: 'shopId', paramsKey: 'shopId'}] },
                    { name: '所属客户', colProp: ['customerName'] },
                    {
                      name: '服务时间',
                      colProp: ['startDate', 'endDate']
                    }
                  ]
                );
              }}},[_vm._v(_vm._s((_vm.line ? _vm.lineData.JDShopNum : _vm.area.addOrder && _vm.area.addOrder.JDShopNum) || "0"))])],1),_c('li',{staticClass:"con-item"},[_vm._m(4),_c('el-button',{staticClass:"pubTextBtn",attrs:{"type":"text"},on:{"click":function () {
                _vm.collect(
                  'pinduoduoShopNum',
                  '拼多多服务中店铺数',
                  [
                    {
                      name: '所属客户',
                      type: 'input',
                      modelKey: 'customerName'
                    }
                  ],
                  [
                    { name: '店铺名称', colProp: ['shopName'], url: '/customer/company-shop-list', paramsArr: [{urlKey: 'shopName', paramsKey: 'shopName'}, {urlKey: 'shopId', paramsKey: 'shopId'}] },
                    { name: '所属客户', colProp: ['customerName'] },
                    {
                      name: '服务时间',
                      colProp: ['startDate', 'endDate']
                    }
                  ]
                );
              }}},[_vm._v(_vm._s((_vm.line ? _vm.lineData.pinduoduoShopNum : _vm.area.addOrder && _vm.area.addOrder.pinduoduoShopNum) || "0"))])],1),_c('li',{staticClass:"con-item"},[_vm._m(5),_c('el-button',{staticClass:"pubTextBtn",attrs:{"type":"text"},on:{"click":function () {
                _vm.collect(
                  'tiktokShopNum',
                  '抖音服务中店铺数',
                  [
                    {
                      name: '所属客户',
                      type: 'input',
                      modelKey: 'customerName'
                    }
                  ],
                  [
                    { name: '店铺名称', colProp: ['shopName'], url: '/customer/company-shop-list', paramsArr: [{urlKey: 'shopName', paramsKey: 'shopName'}, {urlKey: 'shopId', paramsKey: 'shopId'}] },
                    { name: '所属客户', colProp: ['customerName'] },
                    {
                      name: '服务时间',
                      colProp: ['startDate', 'endDate']
                    }
                  ]
                );
              }}},[_vm._v(_vm._s((_vm.line ? _vm.lineData.tiktokShopNum : _vm.area.addOrder && _vm.area.addOrder.tiktokShopNum) || "0"))])],1),_c('li',{staticClass:"con-item"},[_vm._m(6),_c('el-button',{staticClass:"pubTextBtn",attrs:{"type":"text"},on:{"click":function () {
                _vm.collect(
                  'quickWorkerShopNum',
                  '快手服务中店铺数',
                  [
                    {
                      name: '所属客户',
                      type: 'input',
                      modelKey: 'customerName'
                    }
                  ],
                  [
                    { name: '店铺名称', colProp: ['shopName'], url: '/customer/company-shop-list', paramsArr: [{urlKey: 'shopName', paramsKey: 'shopName'}, {urlKey: 'shopId', paramsKey: 'shopId'}] },
                    { name: '所属客户', colProp: ['customerName'] },
                    {
                      name: '服务时间',
                      colProp: ['startDate', 'endDate']
                    }
                  ]
                );
              }}},[_vm._v(_vm._s((_vm.line ? _vm.lineData.quickWorkerShopNum : _vm.area.addOrder && _vm.area.addOrder.quickWorkerShopNum) || "0"))])],1),_c('li',{staticClass:"con-item"},[_vm._m(7),_c('el-button',{staticClass:"pubTextBtn",attrs:{"type":"text"},on:{"click":function () {
                _vm.collect(
                  'otherShopNum',
                  '其他平台服务中店铺数',
                  [
                    {
                      name: '所属客户',
                      type: 'input',
                      modelKey: 'customerName'
                    }
                  ],
                  [
                    { name: '店铺名称', colProp: ['shopName'], url: '/customer/company-shop-list', paramsArr: [{urlKey: 'shopName', paramsKey: 'shopName'}, {urlKey: 'shopId', paramsKey: 'shopId'}] },
                    { name: '所属客户', colProp: ['customerName'] },
                    {
                      name: '服务时间',
                      colProp: ['startDate', 'endDate']
                    }
                  ]
                );
              }}},[_vm._v(_vm._s((_vm.line ? _vm.lineData.otherWorkerShopNum : _vm.area.addOrder && _vm.area.addOrder.otherWorkerShopNum) || 0))])],1)])])]),_c('div',{staticClass:"row5"},[(_vm.projectData && _vm.projectData.length)?_c('div',{staticClass:"col col5-1"},[_c('el-tabs',{staticClass:"xincheng",on:{"tab-click":function () { _vm.saleProjectCharts() }},model:{value:(_vm.radio1),callback:function ($$v) {_vm.radio1=$$v},expression:"radio1"}},_vm._l((_vm.projectData),function(item,index){return _c('el-tab-pane',{key:index,attrs:{"label":item.name || '--',"name":item.id + ''}})}),1),(_vm.flag)?_c('bar-charts',{ref:"barChartsRef",attrs:{"homeData":_vm.projectCharts,"options1":_vm.options1,"barMain":['pro1', 'pro2'],"rightData":_vm.projectCharts,"activeRadio":_vm.radio1,"comId":_vm.comId}}):_vm._e()],1):_c('div',{staticClass:"col col5-1"},[_c('el-empty',{attrs:{"image":_vm.nullImg,"description":"暂无营销项目数据统计"}})],1)]),_c('el-dialog',{attrs:{"title":"年度目标总额","visible":_vm.yearDialogVisible,"width":"30%","before-close":_vm.yearHandleClose,"append-to-body":""},on:{"update:visible":function($event){_vm.yearDialogVisible=$event}}},[_c('div',{staticClass:"demo-input-suffix"},[_vm._v(" 年度目标总额： "),_c('el-input',{staticClass:"common-screen-input",attrs:{"placeholder":"请输入"},model:{value:(_vm.score),callback:function ($$v) {_vm.score=$$v},expression:"score"}})],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{staticClass:"common-screen-btn",on:{"click":function () {
            _vm.yearDialogVisible = false;
          }}},[_vm._v("取 消")]),_c('el-button',{staticClass:"common-screen-btn",attrs:{"type":"primary"},on:{"click":function () {
            _vm.sendYearAim();
          }}},[_vm._v("确 定")])],1)]),_c('common-sum-dialog',{ref:"commonSumDialog",attrs:{"methodFuc":_vm.getMarketReportList,"commonSumVisible":_vm.commonSumVisible},on:{"handleClose":_vm.sumVisibleClose}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row2",attrs:{"id":"sale"}},[_c('div',{staticClass:"col publicColTit"},[_c('span',{staticClass:"publicTotal"},[_vm._v("营销数据统计")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/images/TB.png")}}),_c('span',[_vm._v("淘宝店铺数")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/images/TM.png")}}),_c('span',[_vm._v("天猫店铺数")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/images/JD.png")}}),_c('span',[_vm._v("京东店铺数")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/images/PXX.png")}}),_c('span',[_vm._v("拼多多店铺数")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/images/DY.png")}}),_c('span',[_vm._v("抖音铺数")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/images/KS.png")}}),_c('span',[_vm._v("快手店铺数")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/images/Other.png")}}),_c('span',[_vm._v("其他平台店铺数")])])}]

export { render, staticRenderFns }