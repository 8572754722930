<!--  -->
<template>
  <div class="charts-container">
    <div class="tit">
      <span class="datatit">{{ title }}</span>
      <div>
        <el-radio-group v-model="childType" @change="changeRadio">
          <el-radio-button label="0">昨日</el-radio-button>
          <el-radio-button label="1">近7天</el-radio-button>
          <el-radio-button label="2">近30天</el-radio-button>
          <el-radio-button label="3">近90天</el-radio-button>
        </el-radio-group>
      </div>
    </div>
    <el-select
      v-if="title.indexOf('新增数据统计') > -1"
      class="charts"
      v-model="value"
      placeholder="请选择"
    >
      <el-option
        v-for="item in options1"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
      </el-option>
    </el-select>
    <el-select
      v-else-if="title.indexOf('客服服务数据统计') > -1"
      class="charts"
      v-model="value"
      placeholder="请选择"
      @change="(val)=>{ cusRanking(val) }"
    >
      <el-option
        v-for="item in options2"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
      </el-option>
    </el-select>
    <!-- 判断数据有无，为空显示图片（客服区域） -->
    <div v-show="(title == '客服服务数据统计（已上传数据）' && (homeData&&homeData.orders&&homeData.orders.length)) || title == '新增数据统计' && (homeData&&homeData.addOrder&&homeData.addOrder.length)" :style="`overflow: hidden;height:${value == 1 ? '425px' : 0};`">
      <div :id="lineId[0]" style="height: 425px;"></div>
    </div>
    <div v-show="(title == '客服服务数据统计（已上传数据）' && (homeData&&homeData.orders&&homeData.orders.length)) || title == '新增数据统计' && (homeData&&homeData.addOrder&&homeData.addOrder.length)" :style="`overflow: hidden;height:${value == 2 ? '425px' : 0};`">
      <div :id="lineId[1]" style="height: 425px;"></div>
    </div>
    <div v-show="(title == '客服服务数据统计（已上传数据）' && (homeData&&homeData.orders&&homeData.orders.length)) || title == '新增数据统计' && (homeData&&homeData.addOrder&&homeData.addOrder.length)" :style="`overflow: hidden;height:${value == 3 ? '425px' : 0};`">
      <div :id="lineId[2]" style="height: 425px;"></div>
    </div>
    <div v-show="(title == '客服服务数据统计（已上传数据）' && (homeData&&homeData.orders&&homeData.orders.length)) || title == '新增数据统计' && (homeData&&homeData.addOrder&&homeData.addOrder.length)" :style="`overflow: hidden;height:${value == 4 ? '425px' : 0};`">
      <div :id="lineId[3]" style="height: 425px;"></div>
    </div>
    <div v-show="title == '新增数据统计' && !(homeData&&homeData.addOrder&&homeData.addOrder.length)" class="pit2">
      <el-empty :image="nullImg" description="暂无销售数据"></el-empty>
    </div>
    <div v-show="title == '客服服务数据统计（已上传数据）' && !(homeData&&homeData.orders&&homeData.orders.length)" class="pit2">
      <el-empty :image="nullImg" description="暂无客服数据"></el-empty>
    </div>
  </div>
</template>

<script>
//import引入的组件需要注入到components中才能使用
// import { getLatelyDay } from "../../../utils/index.js";
import elementResizeDetectorMaker from "element-resize-detector"; // 尺寸监听组件
import nullImg from "@/assets/images/line.png"

export default {
  name: "",
  components: {},
  props: ["homeData", "title", "lineId", "type"],
  data() {
    return {
      nullImg,
      childType: this.type, // 筛选类型 7天/30天/90天/ 昨天 ...
      value: "1",
      options1: [
        {
          value: "1",
          label: "新增店铺数"
        },
        {
          value: "2",
          label: "新增订单数"
        },
        {
          value: "3",
          label: "新增客户数"
        },
        {
          value: "4",
          label: "新增销售机会"
        }
      ],
      options2: [
        {
          value: "1",
          label: "咨询量"
        },
        {
          value: "2",
          label: "问答比/邀评率"
        },
        {
          value: "3",
          label: "首响/平响"
        },
        {
          value: "4",
          label: "满意度"
        }
      ],
      arr: [],
      arrKey: []
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    cusRanking(val){ //右侧排行联动
      this.$emit("changeRank",val)
    },
    drawLineCharts() {
      let echartsX = []; //X轴
      let echartsY = []; //Y

      if (this.title == "新增数据统计") {
        this.arr = ["新增店铺数", "新增订单数", "新增客户数", "新增销售机会"];
        this.arrKey = [
          "addShopNum",
          "addOrderNum",
          "addCustomerNum",
          "addChanceNum"
        ];
      } else {
        this.arr = [
          "总咨询量",
          "首响（s）",
          "平响（s）",
          "满意度",
          "问答比",
          "邀评率"
        ];
        this.arrKey = [
          "consultationNum",
          "firsrTime",
          "avgTime",
          "satisfaction",
          "qaRate",
          "inviteComments"
        ];
      }

      if (this.title == "新增数据统计") {
        this.homeData.addOrder.map((item) => {
          echartsX.push(item.day); //X
        });
      } else {
        this.homeData.orders.map((item) => {
          echartsX.push(item.excelDate); //X
        });
      }

      this.arr.map((arrItem, arrIndex) => {
        if (this.title == "新增数据统计") {
          echartsY.push({
          //Y
          data: [], //数据
          name: arrItem, //图例
          type: "line", //线
          smooth: true, //平滑
          // symbol: "none", //取消折点圆圈
          lineStyle: {
            color: "#1890ff" //改变折线颜色
          }
          });
          this.homeData.addOrder.map((item) => {
            echartsY[arrIndex].data.push(item[this.arrKey[arrIndex]]);
          });
        } else {
          echartsY.push({
          //Y
          data: [], //数据
          name: arrItem, //图例
          type: "line", //线
          smooth: true, //平滑
          // symbol: "none", //取消折点圆圈
        });
          this.homeData.orders.map((item) => {
            if (
              this.arrKey[arrIndex] == "inviteComments" ||
              this.arrKey[arrIndex] == "qaRate"
            ) {
              echartsY[arrIndex].data.push(
                (item[this.arrKey[arrIndex]] * 100).toFixed(2) //百分比
              );
            } else {
              echartsY[arrIndex].data.push(item[this.arrKey[arrIndex]]);
            }
          });
        }

        let echartsDataA;
        let echartsDataB;
        let echartsDataC;
        let echartsDataD;
        if (this.title == "新增数据统计") {
          echartsDataA = echartsY.slice(0, 1);
          echartsDataB = echartsY.slice(1, 2);
          echartsDataC = echartsY.slice(2, 3);
          echartsDataD = echartsY.slice(3);
        } else {
          echartsDataA = echartsY.slice(0, 1); //总咨询量
          echartsDataB = echartsY.slice(4); //问答、邀评
          echartsDataC = echartsY.slice(1, 3); //首平
          echartsDataD = echartsY.slice(3, 4);  //满意度
        }
        if (this.title == "新增数据统计") {
          this.echartsLine(
            "echartsLineA",
            echartsDataA,
            echartsX,
            ["新增店铺数"],
            0
          );
          this.echartsLine(
            "echartsLineB",
            echartsDataB,
            echartsX,
            ["新增订单数"],
            1
          );
          this.echartsLine(
            "echartsLineC",
            echartsDataC,
            echartsX,
            ["新增客户数"],
            2
          );
          this.echartsLine(
            "echartsLineD",
            echartsDataD,
            echartsX,
            ["新增销售机会"],
            3
          );
        } else {
          this.echartsLine(
            "echartsLineA",
            echartsDataA,
            echartsX,
            ["总咨询量"],
            0
          );
          this.echartsLine(
            "echartsLineB",
            echartsDataB,
            echartsX,
            ["问答比", "邀评率"],
            1
          );
          this.echartsLine(
            "echartsLineC",
            echartsDataC,
            echartsX,
            ["首响（s）", "平响（s）"],
            2
          );
          this.echartsLine(
            "echartsLineD",
            echartsDataD,
            echartsX,
            ["满意度"],
            3
          );
        }
      });
    },
    echartsLine(byId, echartsY, echartsX, items, num) {
      let yAxis;
      if (this.title == "新增数据统计") {
        yAxis = { type: "value" };
      } else {
        if (byId == "echartsLineB") {
          // 设置Y轴
          yAxis = {
            type: "value",
            axisLabel: {
              formatter: "{value} %"
            }
          };
        } else if (byId == "echartsLineD") {
          yAxis = { type: "value", max: 5 };
        } else {
          yAxis = { type: "value" };
        }
      }
      let myChart = this.$echarts.init(
        document.getElementById(this.lineId[num])
      );
      let option;
      if (echartsX && echartsX.length) {
        // 设置X轴
        option = {
          // width: "95%",
          tooltip: {
            trigger: "axis"
          },
          legend: {
            orient: "horizontal",
            data: items,
            right: "80"
          },
          color: ["#1890ff", "#ff4d4f"], //图例颜色
          grid: {
            left: "5%",
            right: "6%",
            bottom: "6%",
            containLabel: false
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: echartsX
          },
          yAxis: yAxis,
          series: echartsY
        };
      } else {
        option = {
          tooltip: {
            trigger: "axis"
          },
          color: ["#1890ff", "#ff4d4f"], //图例颜色
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: echartsX
          },
          yAxis: {
            type: "value"
          },
          series: echartsY
        };
      }
      myChart.setOption(option);
      
      //////////////////////////////////
      var mainChart = document.getElementById(this.lineId[num]);
      let charts = this.$echarts.init(mainChart); // 图标ID初始化--使用它的resize()
      // 初始化element-resize-detector组件
      let erd = elementResizeDetectorMaker({
        strategy: "scroll", // <- 推荐监听滚动，提升性能
        callOnAdd: true // 添加侦听器时是否应调用,默认true
      })
      erd.listenTo(mainChart, function() {
        // console.log(element,'........');
        charts.resize(); // 当元素尺寸发生改变是会触发此事件，刷新图表
      });
    },
    // 点击切换
    changeRadio(e) {
      let date;
      switch (e) {
        case "0":
          date = 0;
          break;
        case "1":
          date = 7;
          break;
        case "2":
          date = 30;
          break;
        case "3":
          date = 90;
          break;
      }
      this.$emit("changeDate", date, e);
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.drawLineCharts();
  }
};
</script>

<style lang="less" scoped>
.charts-container {
  width: 1163.4px;
  //   margin-top: 32px;
  .tit {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .datatit {
      color: rgba(51, 51, 51, 100);
      font-size: 20px;
    }
    /deep/ .is-active {
      border: none;
      .el-radio-button__inner {
        border: none;
        color: #1890ff !important;
      }
    }
    .el-radio-button {
      height: 16px;
      &:nth-child(-n + 3) {
        border-right: 1px solid #999;
      }
    }
    /deep/.el-radio-button__inner {
      padding: 0 10px;
      border: none;
      background: transparent;
      color: #999999;
      box-shadow: none;
    }
  }
  .charts {
    margin-top: 32px;
    width: 160px;
    /deep/.el-input__inner {
      color: #1890ff;
      background: #f2f9ff;
      border: none;
    }
    /deep/ .el-select__caret {
      color: #1890ff;
    }
  }
}
</style>
